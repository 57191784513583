.PageContainerWrapper {
  display: flex;
  justify-content: center;
}

.PageContainer {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
}

.PageContainer--scroll {
  height: 0;
  flex: 1 1 auto;
  overflow-y: auto;
}

@screen md {
  .PageContainer {
    width: 768px;
    padding-left: 32px;
    padding-right: 32px;
  }
}

@screen lg {
  .PageContainer {
    width: 1024px;
  }
}

@screen xl {
  .PageContainer {
    width: 1280px;
  }
}
