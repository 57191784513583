.squareAvatar {
  position: relative;
}
.squareAvatarLoading,
.squareAvatarHover {
  transition: all 0.2s ease-out;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  bottom: 0;
  background-color: rgb(0, 0, 0, 0.3);
  opacity: 0;
  z-index: 1;
}

.squareAvatarLoading {
  opacity: 1;
}

.squareAvatar:hover .squareAvatarHover {
  transition: all 0.2s ease-in;
  /* height: 23%; */
  background-color: rgb(0, 0, 0, 0.5);
  opacity: 1;
  z-index: 1;
}
